import { FC, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import logo from "@/img/logo.png";
import locationWhite from "@/img/location_white.svg";
import magnifyingGlassWhite from "@/img/magnifying_glass_white.svg";
import userWhite from "@/img/user_white.svg";
import MobileMenu from "../MobileMenu";

interface NavbarProps {
  searchQuery?: string;
  setSearchQuery?: (newSearchQuery: string) => void;
  zipcode: string;
  setZipcode: (newZipcode: string) => void;
  handleLocationInputClick: () => void;
  isLanding?: boolean;
  handleSubmit: (e, modalZipcode?) => void;
  isLoading?: false;
}

interface LoadingNavbarProps {
  searchQuery: string;
  zipcode: string;
  isLoading: true;
  isLanding?: false;
}

const Navbar: FC<NavbarProps | LoadingNavbarProps> = (props) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      {showMobileMenu && <MobileMenu setShowMobileMenu={setShowMobileMenu} />}
      <header>
        {/* Navbar */}
        <nav className="sticky left-0 top-0 z-20 min-h-full w-full content-center border-b border-gray-200 bg-youdleDarkBlue dark:border-gray-600">
          <div className="flex min-h-full w-full flex-wrap items-center justify-between px-6 py-5 md:px-8">
            <Link href="/" className="flex h-full items-center">
              <Image
                className="mr-3"
                src={logo}
                alt="Youdle logo"
                height={44}
              />
              <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white"></span>
            </Link>

            {!props.isLanding && (
              <div className="order-2 md:order-1">
                {!props.isLoading ? (
                  <SearchBar {...(props as NavbarProps)} />
                ) : (
                  <LoadingSearchBar {...props} />
                )}
              </div>
            )}

            <NavLinks />

            <MobileButton setShowMobileMenu={setShowMobileMenu} />
          </div>
        </nav>
      </header>
    </>
  );
};

const SearchBar: FC<NavbarProps> = ({
  searchQuery,
  setSearchQuery,
  zipcode,
  setZipcode,
  handleLocationInputClick,
  handleSubmit,
}) => {
  return (
    <div className="flex w-full flex-col items-center justify-center pt-2 md:w-fit md:pt-0">
      <form
        className="flex w-max flex-col items-center sm:flex-row "
        id="form"
        role="search"
        onSubmit={(e) => {
          handleSubmit(e, zipcode);
        }}
      >
        {/* {% csrf_token %} */}
        <div className="container h-12 w-[87vw] rounded bg-blue-600 md:inline md:w-max">
          <input
            type="search"
            className="w-2/3 rounded bg-blue-600 py-3 pl-4 text-base text-white hover:bg-blue-500 focus:bg-blue-600  focus:outline-blue-500 sm:mb-2 md:w-7/12"
            id="query"
            name="searched"
            placeholder="Search Product"
            minLength={3}
            pattern=".{3,}"
            onInvalid={(e) =>
              e.currentTarget.setCustomValidity(
                "Your search query must be at least 3 characters long.",
              )
            }
            required
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              console.log(e.target.value);
              console.log(searchQuery);
              e.currentTarget.setCustomValidity("");
            }}
          />

          <div className="inline hover:bg-blue-500">
            <i className="absolute ml-[-.5rem] py-4">
              <Image src={locationWhite} alt="" />
            </i>
            <input
              type="search"
              className="w-1/3 overflow-visible rounded bg-blue-600 py-3 pl-4 pr-4 text-base text-white hover:bg-blue-500 focus:bg-blue-600 focus:outline-blue-500 sm:mb-0"
              id="zipcode"
              name="zipcode"
              placeholder="Location"
              pattern="(^\d{5}$)|(^\d{5}-\d{4}$)"
              minLength={5}
              maxLength={5}
              onInvalid={(e) =>
                e.currentTarget.setCustomValidity(
                  "Your zip code must be exactly 5 characters long.",
                )
              }
              required
              value={zipcode}
              onClick={handleLocationInputClick}
              onChange={(e) => {
                console.log(zipcode);
                setZipcode(e.target.value);
                e.currentTarget.setCustomValidity("");
              }}
            />
          </div>
          <button
            type="submit"
            id="submit"
            onSubmit={(e) => {
              handleSubmit(e, zipcode);
            }}
            //   onClick={handleSubmit}
            className="absolute mt-2 hidden w-10 rounded bg-red p-2 pr-1 hover:bg-red-500 md:ml-[-.75rem] md:mt-[5px] md:inline"
          >
            <a>
              <Image src={magnifyingGlassWhite} alt="" />
            </a>
          </button>
        </div>
      </form>
    </div>
  );
};

const LoadingSearchBar: FC<LoadingNavbarProps> = ({ searchQuery, zipcode }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center pt-2 md:w-fit md:pt-0">
      <form
        className="flex w-max flex-col items-center sm:flex-row "
        id="form"
        role="submit"
        action="javascript:void(0);"
        onSubmit={() => {
          return false;
        }}
      >
        {/* {% csrf_token %} */}
        <div className="container h-12 w-[87vw] rounded bg-blue-600 md:inline md:w-max">
          <input
            className="w-2/3 rounded bg-blue-600 py-3 pl-4 text-base text-white hover:bg-blue-500 focus:bg-blue-600  focus:outline-blue-500 sm:mb-2 md:w-7/12"
            placeholder="Search Product"
            value={searchQuery}
          />

          <div className="inline hover:bg-blue-500">
            <i className="absolute ml-[-.5rem] py-4">
              <Image src={locationWhite} alt="" />
            </i>
            <input
              className="w-1/3 overflow-visible rounded bg-blue-600 py-3 pl-4 pr-4 text-base text-white hover:bg-blue-500 focus:bg-blue-600 focus:outline-blue-500 sm:mb-0"
              placeholder="Location"
              value={zipcode}
              onClick={() => {
                null;
              }}
            />
          </div>
          <button
            type="submit"
            id="submit"
            onSubmit={() => {
              return false;
            }}
            //   onClick={handleSubmit}
            className="absolute mt-2 hidden w-10 rounded bg-red p-2 pr-1 hover:bg-red-500 md:ml-[-.75rem] md:mt-[5px] md:inline"
          >
            <a>
              <Image src={magnifyingGlassWhite} alt="" />
            </a>
          </button>
        </div>
      </form>
    </div>
  );
};

const NavLinks: FC = () => {
  return (
    <div
      className="hidden h-full w-full items-center justify-end self-end pt-2 md:order-1 md:flex md:w-auto"
      id="navbar-sticky"
    >
      <ul className="border-gray-100 flex flex-col justify-end rounded-lg border font-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:p-0">
        {/* <li>
        <Link
          href="/sell"
          className="block rounded py-2 pl-3 pr-4 text-white md:pl-0"
        >
          Sell
        </Link>
      </li> */}
        {/* <li>
        <Link
          href="watchlist"
          className="block rounded py-2 pl-3 pr-4 text-white md:pl-0"
        >
          Watchlist
        </Link>
      </li> */}
        <li>
          <Link
            href="https://www.facebook.com/groups/getyoudle"
            className="block rounded pl-3 pr-4 pt-2 text-white md:pl-0"
            aria-current="page"
          >
            Youdle Nation
          </Link>
        </li>
        <li>
          <Link
            href="https://news.youdleit.com/"
            className="block rounded py-2 pl-3 pr-4 text-white md:pl-0"
          >
            News
          </Link>
        </li>
        <li>
          <Link
            href="https://youdle.printify.me/products"
            className="block rounded py-2 pl-3 pr-4 text-white md:pl-0"
          >
            Shop
          </Link>
        </li>
        <li>
          <Link
            href="https://forms.gle/mmFXxZwx71bjnKta8"
            className="block rounded pl-3 pr-4 pt-2 text-white md:pl-0"
            aria-current="page"
          >
            Feedback
          </Link>
        </li>
        {/* <li>
        <Link
          href="about-us"
          className="block rounded py-2 pl-3 pr-4 text-white md:pl-0"
        >
          About Us
        </Link>
      </li> */}

        <li>
          <button className="rounded-full border border-white px-2 py-2 hover:bg-blue-500">
            <Link href="/signin">
              <Image className="w-6" src={userWhite} alt="" />
            </Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

const MobileButton: FC<{ setShowMobileMenu: (boolean) => void }> = ({
  setShowMobileMenu,
}) => {
  return (
    <div className="align-center flex md:order-2 md:hidden">
      <button
        data-collapse-toggle="navbar-sticky"
        type="button"
        className="text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 inline-flex items-center rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600 md:hidden"
        aria-controls="navbar-sticky"
        aria-expanded="false"
        onClick={() => setShowMobileMenu(true)}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-8"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="/images/hamburger_menu.svg"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default Navbar;
